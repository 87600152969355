import React from 'react'
import Helmet from 'react-helmet'

const schemaOrgJSONLD = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  url: 'https://www.ospedaleveterinario.unimi.it',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Lodi (LO), Italia',
    postalCode: '26900',
    streetAddress: "via dell'Università, 6",
  },
  email: 'ospedalevet(at)unimi.it',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+390250334116',
      email: 'ospedalevet(at)unimi.it',
      contactType: 'customer service',
      hoursAvailable: {
        dayOfWeek: 'Mo,Tu,We,Th,Fr',
        opens: '09:00:00',
        closes: '17:00:00',
      },
    },
    {
      '@type': 'ContactPoint',
      telephone: '+3950334008',
      email: 'degenza.ospedaleveterinario(at)unimi.it',
      contactType: 'emergency',
      hoursAvailable: {
        dayOfWeek: 'Mo,Tu,We,Th,Fr',
        opens: '09:00:00',
        closes: '17:00:00',
      },
    },
    {
      '@type': 'ContactPoint',
      telephone: '+390250334116',
      email: 'degenza.ospedaleveterinario(at)unimi.it',
      contactType: 'emergency',
      hoursAvailable: [
        {
          dayOfWeek: 'Mo,Tu,We,Th,Fr',
          opens: '17:00:00',
          closes: '09:00:00',
        },
        {
          dayOfWeek: 'Sa,Su',
        },
      ],
    },
  ],
}

export const onetrustShowSettings = event => {
  if (event.stopImmediatePropagation) {
    event.stopImmediatePropagation()
  } else {
    event.nativeEvent.stopImmediatePropagation()
  }
  if (window.OneTrust) {
    window.OneTrust.ToggleInfoDisplay()
  }
}
class Footer extends React.Component {
  render() {
    const navigation = this.props.navigation
    return (
      <footer className="footer" role="contentinfo">
        {/* Schema.org tags */}
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
          </script>
        </Helmet>

        <div className="footer__top">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <p>OSPEDALE VETERINARIO UNIVERSITARIO</p>
                <div className="footer__info">
                  <p>
                    <strong>Sede</strong>
                    <br />
                    <span className="adr">
                      <span className="street-address">
                        via dell'Università, 6
                      </span>
                      <br />
                      <span className="postal-code">26900 </span>
                      <span className="locality">Lodi (LO)</span> -{' '}
                      <span className="country-name">Italia</span>
                    </span>
                  </p>
                  <p>
                    <strong>Segreteria</strong>
                    <br />
                    <span>02/503.34116</span> -{' '}
                    <span>direzione.sanitaria.vet@unimi.it</span>
                    <br />
                    Orario di ufficio: 9.00-17.00
                  </p>
                </div>
              </div>
              {/*
          <div className="col-md-3">
            <div className="footer__social">
              <a href="/" title="Facebook"><i className="fa fa-facebook-official" aria-hidden="true"></i></a>
              <a href="/" title="YouTube"><i className="fa fa-youtube" aria-hidden="true"></i></a>
            </div>
          </div>
          */}
            </div>
          </div>
        </div>
        <div className="footer__bottom text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer__nav">
                  <ul className="footer__list">
                    <li className="footer__item footer__item--first">
                      Copyright ©
                    </li>
                    {navigation.map((item, index) => {
                      return (
                        <li key={index} className="footer__item">
                          {' '}
                          -{' '}
                          <a
                            href={item.link}
                            title={item.name}
                            className="footer__link"
                          >
                            {item.name}
                          </a>
                        </li>
                      )
                    })}{' '}
                    -{' '}
                    <li className="footer__item">
                      <button
                        className="footer__item--ot"
                        onClick={onetrustShowSettings}
                      >
                        Cookie Settings
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
