import React from 'react'
import { Link } from 'gatsby'

class Team extends React.Component {

  render() { 
    let servizioid = this.props.data.contentful_id;
    let team = this.props.data.team || [];
    let tipo = this.props.tipo;
    let elenco = this.props.elenco || false;
    
    return (
      <div className="webcontent">
        <h2 className="webcontent__title">Il Team</h2>
        <div className="webcontent__abstract">
         {/*  <p>
            Conosci il team del servizio. Professionalit&agrave; ed assistenza ai pi&ugrave; alti livelli.
          </p> */}
          {elenco ? (
            <ul>
                {team.map((item,index) => {
                  return (
                    <li key={index}>
                      <Link to={'/staff/'+item.friendlyUrl} title={"Vai alla scheda di " + item.cognome}>
                        {item.nome} {item.cognome}
                      </Link>
                      <br/>
                      <em>{item.ruolo}</em>
                    </li>
                  )
                })}
            </ul>
          ) : (
            <Link to={"/staff?id="+servizioid+"&tipo="+tipo}>Conosci lo staff medico</Link>
          )}
        </div>
      </div>
    )
  }

}

export default Team;