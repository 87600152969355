import React from 'react'


class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: [0]
    }
  }

  handleClick(e,index) {
    e.preventDefault();

    let tmp = this.state.open;
    let ip = tmp.indexOf(index);
    if (ip !== -1) {
      tmp.splice(ip,1);
    } else {
      tmp.push(index);
    }
    
    this.setState({
      open: tmp
    });
    
  }

  render() { 
    const data = this.props.data;
    let open = this.state.open;
    
    return (
        <div id="accordion" className="panel-group" aria-multiselectable="true">

          {data.map((item,index) => { 
            const openClass = open.includes(index)?"open":"";
          
            return <div key={index} className={"faq__item "+openClass}>
              <div id={"heading"+index} className="faq__title" role="tab">
                <h4 className="panel-title">
                  <a onClick={(e) => this.handleClick(e,index)} href={"#collapse"+index} role="button" data-toggle="collapse" data-target={"#collapse"+index} data-parent="#accordion" aria-expanded="true" aria-controls="collapse1">
                    {item.domanda}
                  </a>
                </h4>
              </div>
              <div id={"collapse"+index} className="faq__content" role="tabpanel" aria-labelledby={"heading"+index} data-parent="#accordion">
                <div className="panel-body" dangerouslySetInnerHTML={{ __html: item.risposta.childMarkdownRemark.html }} >
                </div>
              </div>
            </div>
          })}
          
        </div>
     
    )
  }

}

export default Faq;