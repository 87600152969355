import React from 'react'
import { Link, navigate } from 'gatsby'
import AgAutocomplete from 'react-algoliasearch'

const utils = require('../utility/navigation.js')

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileClass: '',
    }
  }

  goTo = (e, obj, dataset) => {
    if (obj.url) {
      if (typeof window !== 'undefined') {
        window.location.href = obj.url
      } else {
        var url = new URL(obj.url)
        navigate(url.pathname)
      }
    }
  }

  handleClick(e) {
    e.preventDefault()
    let cssClass = this.state.mobileClass === 'open' ? '' : 'open'
    this.setState({
      mobileClass: cssClass,
    })
  }

  render() {
    const { mobileClass } = this.state
    const navigation = this.props.navigation
    const pathname = this.props.pathname

    return (
      <header className="header" role="banner">
        {/* header top */}
        <div className="header__top">
          <div className="container">
            <div className="row">
              <div className="col-12 clearfix">
                <Link
                  to="/emergenza/"
                  title="Emergenze 24h"
                  className="emergenza"
                >
                  <i className="fa fa-plus" aria-hidden="true" />
                  Emergenze 24h
                </Link>
                {/*
            <div className="language">
              <ul className="language__list">
                <li className="language__item"><a href="/" title="IT" className="language__link" hrefLang="it">IT</a></li>
                <li className="language__item"><a href="/" title="EN" className="language__link" hrefLang="en">EN</a></li>
              </ul>
            </div>
            */}
              </div>
            </div>
          </div>
        </div>
        {/* header middle */}
        <div className="header__middle">
          <div className="container">
            <div className="row">
              <div className="col-3 col-md-6">
                <div className="logo">
                  <Link
                    to="/"
                    title="UniMi | Ospedale Vetrinario Universitario"
                    className="logo__link"
                  >
                    <img
                      src="/images/logo-ovul.png"
                      alt="UniMi | Ospedale Vetrinario Universitario"
                      className="logo__img d-print-none"
                    />
                    <span className="logo__name visuallyhidden d-print-block">
                      UniMi | Ospedale Vetrinario Universitario
                    </span>
                  </Link>
                 
                </div>
              </div>
              <div className="col-6 col-md-6 d-print-none">
                <div className="logo-unimi">
                  <a
                    href="http://www.unimi.it/"
                    title="UniMi"
                    className="logo-unimi__link"
                  >
                    <img
                      src="/images/logo-unimi.png"
                      alt="UniMi"
                      className="logo-unimi__link"
                    />
                    <span className="visuallyhidden d-print-block">UniMi</span>
                  </a>
                </div>
              </div>
              <div className="col-3 d-block d-md-none d-print-none">
                <button
                  id="toggle-menu-btn"
                  className={'hamburger ' + mobileClass}
                  onClick={e => this.handleClick(e)}
                >
                  <span>&#160;</span>
                  <span>&#160;</span>
                  <span>&#160;</span>
                  <span>&#160;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  header bottom */}
        <div className={'header__bottom ' + mobileClass}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hb-wrapper clearfix">
                  {!!navigation && (
                    <nav
                      id="nav"
                      className="navigation hidden-print"
                      itemType="http://schema.org/SiteNavigationElement"
                      itemScope="itemscope"
                      role="navigation"
                    >
                      <ul
                        className="navigation__list"
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="menu"
                        aria-orientation="horizontal"
                      >
                        {navigation.map((item, index) => {
                          let isfirst =
                            index === 0 ? 'navigation__item--first ' : ''
                          let haschildren = !!item.children
                            ? 'navigation__item--has_sub '
                            : ''
                          let isActive = utils.isActive(pathname, item)
                          return (
                            <li
                              key={index}
                              className={
                                'navigation__item ' +
                                isfirst +
                                haschildren +
                                isActive
                              }
                              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                              role="menuitem"
                              itemProp="name"
                            >
                              <Link
                                to={item.redirect || item.link}
                                title={item.name}
                                className="navigation__link"
                                itemProp="url"
                              >
                                {item.name}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    </nav>
                  )}

                  <div className="search">
                    <span className="search__submit">
                      <i className="fa fa-search" aria-hidden="true" />
                    </span>
                    <AgAutocomplete
                      apiKey={'aa8dc447ac4d783b1da2e86d41edc421'}
                      appId={'CO7XH57TFJ'}
                      displayKey="title"
                      keyName="title"
                      indices={[{ index: 'Ovul' }]}
                      inputId="input-search"
                      placeholder="Cerca nel sito"
                      selected={this.goTo}
                      options={{
                        debug: true,
                        title: 'Cerca nel sito',
                        minLength: 3,
                        templates: {
                          footer:
                            '<div class="branding">Powered by <img alt="Algolia" src="https://www.algolia.com/static_assets/images/press/downloads/algolia-logo-light.svg" /></div>',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
