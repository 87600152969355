import React from 'react'
import Lightbox from 'react-images'
import { graphql } from 'gatsby'

class PhotoGallery extends React.Component {
  constructor() {
    super()
    this.state = { currentImage: 0 }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
  }
  openLightbox(event, index) {
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    })
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }
  render() {
    const data = this.props.data
    let images = []
    data.forEach(function(item) {
      var elem = {}
      elem.src = item.gallery.src
      elem.width = 480
      elem.height = 320
      images.push(elem)
    })
    var lightbox = ''
    if (this.state.lightboxIsOpen) {
      lightbox = (
        <Lightbox
          images={images}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      )
    }
    return (
      <div className="popup-gallery">
        <div className="row">
          {data.map((item, index) => {
            return (
              <div key={index} className="col-6 col-md-4">
                <div className="popup-gallery__image">
                  <button
                    className="popup-gallery__image-button"
                    onClick={e => this.openLightbox(e, index)}
                  >
                    <img src={item.thumbnail.src} alt={item.title} />
                  </button>
                </div>
              </div>
            )
          })}
          {lightbox}
        </div>
      </div>
    )
  }
}

export default PhotoGallery

export const PhotoGalleryFragment = graphql`
  fragment PhotoGalleryFragment on ContentfulAsset {
    title
    thumbnail: fluid(maxHeight: 180) {
      sizes
      src
      srcSet
    }
    gallery: fluid(maxWidth: 1920) {
      src
    }
  }
`
