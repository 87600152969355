/* eslint-disable */
const addTrailingSlash = string => string ? string.replace(/\/?$/, '/') : string;
const pathRegExp = /(^\/.*?)\//;
const getFirstPath = string => string ? pathRegExp.exec(string) : string;
//const replaceSpacesWithDash = string => string ? string.replace(new RegExp(' ', 'g'), '-') : string;


module.exports = {
    normalizePathName: pathname => {
        // Normalize pathname (remove final '/') and explicitly set index.html
        if (pathname.lastIndexOf('/') === pathname.length - 1) {
            pathname = pathname.substring(0, pathname.lastIndexOf('/'));
        }
        if (pathname === '') {
            pathname = '/index.html';
        }
        return pathname;
    },
    isActive:(pathname,item) => {
      
      const className = "navigation__item--active";
      
      const firstpath = getFirstPath(pathname) ? getFirstPath(pathname)[1] : pathname;
      pathname = addTrailingSlash(firstpath);
     
      if ((pathname === '/index.html' && item.link ==='/') || pathname === item.link ) {
            return className;
        } else if (item.children) {
            const found = item.children.some(function(child) {
                if (pathname === child.link) {
                    return true; 
                }
            });
            if (found) {
                return className;
            }
        }
        return "";
    },
    getPageInfo: (pathname,navigation) => {
        let currentPage = navigation[0];
        navigation.some(function(child1) {
            if (child1.link === pathname) {
                currentPage = child1;
            } else if (child1.children) {
                const child2 = child1.children.some(function(child2) {
                if ( child2.link === pathname) {
                    currentPage = child2;
                }
                });
            }
        });
        return currentPage;
    },
    getBreadcrumb: (pathname,navigation) => {
      let breadcrumb = [];
      navigation.some(function(child1) {
        if (child1.link === pathname) {
          breadcrumb.push(child1);
        }
        if (child1.children) {
          const child2 = child1.children.some(function(child2) {
            if ( child2.link === pathname) {
              breadcrumb.push(child1);
              breadcrumb.push(child2);
            }
          });
        }
      });
      return breadcrumb;
    }

    
}