import React from 'react'
import Helmet from 'react-helmet'
import PhotoGallery from '../components/photogallery'
import Faq from '../components/faq'
import Contatti from '../components/contatti'
import Team from '../components/team'
import Breadcrumbs from '../components/breadcrumbs'
import DocumentiUtili from '../components/documenti-utili'
import Layout from "../components/layout"
import { graphql } from "gatsby"

const utils = require("../utility/utils.js");
class ServizioTemplate extends React.Component {

  render() { 
    const data = this.props.data.contentfulApprofondimentoServizio;
    const servizi = this.props.data.allContentfulSchedaServizio;
    const diagnostica = this.props.data.allContentfulSchedaServizioDiagnostico;
    let specie;
    let specieslug;
    let servizio;
    let servizioslug;
    let btn = {};
    let breadcrumb = [
      {"title":"Reparti","link":"/reparti/"}
    ];

    if (!!servizi && servizi.edges.length > 0) {
      specie = servizi.edges[0].node.specie[0];
      specieslug = "/"+utils.slugify(specie)+"/";
      servizio = servizi.edges[0].node.titolo;
      servizioslug = specieslug  + servizi.edges[0].node.friendlyUrl +"/";
      breadcrumb.push({"title": specie,"link": specieslug});
      breadcrumb.push( {"title": servizio,"link": servizioslug});
    } else  if (!!diagnostica && diagnostica.edges.length > 0) {
      servizio = diagnostica.edges[0].node.titolo;
      servizioslug = "/"+diagnostica.edges[0].node.friendlyUrl +"/";
      breadcrumb.push( {"title": servizio,"link": servizioslug});
      if (servizioslug === '/patologia-e-diagnostica-di-laboratorio/') {
        btn = {"label":"Come conferire un campione","link":"/patologia-e-diagnostica-di-laboratorio#conferire"};
      }
    } 
    breadcrumb.push({"title":  data.titolo});
    
    return (
      <Layout  location={this.props.location}>
        <div className="container">
          <Helmet title={data.titolo}/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <div className="servizi" data-id={data.id}>
                <h1 className="servizi__title">{data.titolo}</h1>
                {!!data.descrizione &&
                  <div className="servizi__bodytext" 
                      dangerouslySetInnerHTML={{ __html: data.descrizione.childMarkdownRemark.html }} >
                  </div>
                }
        
                
                {!!data.imageGallery &&
                  <div className="magnific-popup">
                    <h2 className="servizi__subject">Fotogallery</h2>
                    <PhotoGallery data={data.imageGallery}/>
                  </div>
                }
                {!!data.faq &&
                  <div className="faq">
                    <h2 className="servizi__subject">FAQ</h2>
                    <Faq data={data.faq}/>
                  </div>
                }
              </div>
            </div>
            <div className="col-md-4">
            
              {!!data.documentiUtili &&
                <DocumentiUtili data={data.documentiUtili}/>
              }
              <Team data={data} tipo="d" elenco="true"/>
              <Contatti data={data}/>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default ServizioTemplate;

export const pageQuery = graphql`query ApprofondimentoDetailQuery($contentful_id: String!) {
  contentfulApprofondimentoServizio(contentful_id: {eq: $contentful_id}) {
    id
    titolo
    descrizione {
      childMarkdownRemark {
        html
      }
    }
    indirizzo
    contatti {
      childMarkdownRemark {
        html
      }
    }
    telefono
    mail
    team {
      cognome
      nome
      friendlyUrl
      ruolo
    }
    faq {
      domanda
      risposta {
        childMarkdownRemark {
          html
        }
      }
    }
    
    imageGallery {
      ...PhotoGalleryFragment
    }
  }
  allContentfulSchedaServizio(filter: 
    {approfondimento: {elemMatch: {contentful_id: {eq: $contentful_id}}}, node_locale: {eq: "it-IT"} }) {
    edges {
     node {
        titolo
        friendlyUrl
        specie
    	}
    }
  }
  allContentfulSchedaServizioDiagnostico(filter: 
    {approfondimento: {elemMatch: {contentful_id: {eq: $contentful_id}}}, node_locale: {eq: "it-IT"} }) {
    edges {
     node {
      titolo
      friendlyUrl
    	}
    }
  }
}
`;
