import React from 'react'

class Contatti extends React.Component {

  render() { 
    const data = this.props.data;
    let emails = data.email?data.email.split(";"):[];
    let mails = data.mail?data.mail.split(";"):[];
    return (
      <div className="webcontent webcontent--noborder">
        <h2 className="webcontent__title">Contatti</h2>
        <div className="webcontent__abstract">
          {!!data.contatti &&
            <p dangerouslySetInnerHTML={{ __html: data.contatti.childMarkdownRemark.html }}>  
            </p>
          }
          {!!data.indirizzo &&
            <div className="icon icon--marker">{data.indirizzo}</div>
          }
          {!!data.telefono &&
            <div className="icon icon--phone">{data.telefono}</div>
          }
          {emails.map((item,index) => {
            return (
              <div key={item} className="icon icon--mail">{item}</div>
            )
            
            })
          }
          {mails.map((item,index) => {
            return (
              <div key={item} className="icon icon--mail">{item}</div>
            )
            })
          }
        </div>
      </div>
    )
  }

}

export default Contatti;