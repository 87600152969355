import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../css/custom.scss'

import Header from './header'
import Footer from './footer'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
        navigationJson {
          header {
            name
            link
            redirect
            children {
              name
              link
            }
          }
          footer {
            name
            link
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          defaultTitle={`Ospedale Veterinario Universitario di Lodi`}
          titleTemplate={`%s | Ospedale Veterinario Universitario di Lodi`}
        >
          <html lang="it" />
          <link
            type="application/rss+xml"
            title="RSS news ed eventi"
            href={data.site.siteMetadata.siteUrl + '/news.xml'}
          />
          {/* OneTrust Cookies Consent Notice start for www.ospedaleveterinario.unimi.it */}

          <script
            type="text/javascript"
            src="https://cdn.cookielaw.org/consent/e3d17b93-f081-4fbd-93a3-86b6dcca3c93-test/OtAutoBlock.js"
          />

          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script="e3d17b93-f081-4fbd-93a3-86b6dcca3c93-test"
          />

          <script type="text/javascript">{`function OptanonWrapper() {}`}</script>

          {/* OneTrust Cookies Consent Notice end for www.ospedaleveterinario.unimi.it */}
        </Helmet>
        <Header
          pathname={location ? location.pathname : '/'}
          siteTitle={data.site.siteMetadata.title}
          navigation={data.navigationJson.header}
        />

        <div className="main-wrapper">{children}</div>
        <Footer navigation={data.navigationJson.footer} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
