import React from 'react'

class DocumentiUtili extends React.Component {

  render() { 
    const docs = this.props.data;
   
    return (
      <div className="webcontent">
        <h2 className="webcontent__title">Documenti utili</h2>
        <ul className="webcontent__list">
          {docs.map((item, index) => {
          return <li key={index} className="webcontent__item">
            <a  href={item.file.url} title={item.title} className="webcontent__link">{item.title}</a>
          </li>
          })}
        </ul>
      </div>
    )
  }

}

export default DocumentiUtili;